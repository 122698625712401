function CohEventHandler () {
    this.vue = new Vue();
}
CohEventHandler.prototype = {
    constructor: CohEventHandler,
    fire: function (event, data1, data2) {
        data1 = data1 === undefined ? null : data1;
        data2 = data2 === undefined ? null : data2;

        this.vue.$emit(event, data1, data2);
    },
    listen: function (event, callback) {
        this.vue.$on(event, callback);
    }
};

window.CohEvent = new CohEventHandler();
